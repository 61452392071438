.skills-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 50px;
  width: 95%;
  border-radius: 20px;
  color: white;
  max-width: 1200px;
  height: 550px;
  font-size: 1.5rem;
  background-color: #99aaff;
  background-image: radial-gradient(
      at 71% 96%,
      hsla(234, 77%, 63%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 5% 84%, hsla(323, 60%, 62%, 1) 0px, transparent 50%),
    radial-gradient(at 48% 93%, hsla(232, 66%, 73%, 1) 0px, transparent 50%),
    radial-gradient(at 65% 0%, hsla(276, 70%, 67%, 1) 0px, transparent 50%),
    radial-gradient(at 91% 58%, hsla(132, 73%, 68%, 1) 0px, transparent 50%),
    radial-gradient(at 74% 95%, hsla(220, 67%, 71%, 1) 0px, transparent 50%),
    radial-gradient(at 62% 66%, hsla(120, 74%, 63%, 1) 0px, transparent 50%);
}
.skills-heading {
  margin: 20px 0;
  font-size: 3rem;
  color: white;
}

.skills-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}

.skills-list {
  list-style-type: none;
  margin: 10px 30px;
}

@media (max-width: 600px) {
  .skills-list {
    font-size: 1.2rem;
  }

  .skills-container {
    background-size: 130% 130%;
  }
}
