.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-heading {
  color: #333333;
  margin-top: 50px;
  font-size: 3rem;
  margin-bottom: -30px;
}

.projects-list {
  list-style-type: none;
}
